import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import Functional_bg from "../../images/templates/Functional_bg.jpeg"
import Monaco_bg from "../../images/templates/backgrounds/Monaco_bg.jpeg"
import Green_Sea_bg from "../../images/templates/backgrounds/Green_Sea_bg.jpeg"
import Waterfall_bg from "../../images/templates/backgrounds/Waterfall_bg.jpeg"
import Arielle_bg from "../../images/templates/backgrounds/Arielle_bg.jpeg"
import Arya_bg from "../../images/templates/backgrounds/Arya_bg.jpeg"
import Imperial_bg from "../../images/templates/backgrounds/Imperial_bg.jpeg"
import Simple_bg from "../../images/templates/backgrounds/Simple_bg.jpeg"
import Midnight_bg from "../../images/templates/backgrounds/Midnight_bg.jpeg"
import Marine_bg from "../../images/templates/backgrounds/Marine_bg.jpeg"
import ATS_bg from "../../images/templates/backgrounds/ATS_bg.jpeg"

import Functional from "../../images/templates/Functional.png"
import Monaco from "../../images/templates/Monaco.png"
import Green_Sea from "../../images/templates/Green_Sea.png"
import Waterfall from "../../images/templates/Waterfall.png"
import Arielle from "../../images/templates/Arielle.png"
import Arya from "../../images/templates/Arya.png"
import Imperial from "../../images/templates/Imperial.png"
import Simple from "../../images/templates/Simple.png"
import Midnight from "../../images/templates/Midnight.png"
import Marine from "../../images/templates/Marine.png"
import ATS from "../../images/templates/ATS_TWO_COLUMNS.png"

import "./style.css"

const templates = [
  {
    title: "Functional",
    imageURL: Functional,
    bgURL: Functional_bg,
  },
  {
    title: "Monaco",
    imageURL: Monaco,
    bgURL: Monaco_bg,
  },
  {
    title: "Green_Sea",
    imageURL: Green_Sea,
    bgURL: Green_Sea_bg,
  },
  {
    title: "Waterfall",
    imageURL: Waterfall,
    bgURL: Waterfall_bg,
  },
  {
    title: "Arielle",
    imageURL: Arielle,
    bgURL: Arielle_bg,
  },
  {
    title: "Arya",
    imageURL: Arya,
    bgURL: Arya_bg,
  },
  {
    title: "Imperial",
    imageURL: Imperial,
    bgURL: Imperial_bg,
  },
  {
    title: "Simple",
    imageURL: Simple,
    bgURL: Simple_bg,
  },
  {
    title: "Midnight",
    imageURL: Midnight,
    bgURL: Midnight_bg,
  },
  {
    title: "Marine",
    imageURL: Marine,
    bgURL: Marine_bg,
  },
  {
    title: "ATS",
    imageURL: ATS,
    bgURL: ATS_bg,
  },
]

const Templates = () => (
  <Layout>
    <Seo title="Templates" />
    <div className="templates-section-container">
      <div className="templates-section">
        <div className="templates-section-heading-container">
          <h1>Free Resume Templates</h1>
          <p>
            <b>nanoresume</b> got you covered with all the necessary tools to
            build a perfect resume – entirely for free. To begin, choose a
            resume template design that suits you best: from a professional and
            ATS-friendly to a more modern or creative one.
          </p>
        </div>
        <div className="templates-list">
          {templates.map(template => (
            <Template {...template} />
          ))}
        </div>
        <div className="templates-section-view-more">
          <Link to="http://app.nanoresume.com/onboarding">
            View More Templates
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

const Template = ({ bgURL, imageURL, title }) => (
  <div className="template-container">
    <div className="template-bg" style={{ backgroundImage: `url("${bgURL}")` }}>
      <div
        className="template"
        style={{ backgroundImage: `url("${imageURL}")` }}
      />
    </div>
  </div>
)

export default Templates
